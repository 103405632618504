<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Programs',

    metaInfo: { title: 'Programs' },

    extends: View,

    mixins: [
      LoadSections([
        'programs',
        'info-only',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'programs',
      },
    },
  }
</script>
